export default () => {
  const isLoading = ref(false);
  const localePath = useLocalePath();

  const updateRequestState = async(requestId, stateId) => {
    isLoading.value = true;

    try {
      await useNuxtApp().$store.dispatch('operator/requests/updateRequest', {
        id: requestId,
        data: {
          state_id: stateId,
          operator_id: useAuthStore().user?.id,
        },
      });
    } catch (error) {
      console.error(error);

      ElMessage.error({
        message: 'Došlo k chybě při změně stavu požadavku',
        duration: 4000,
        showClose: true,
      });
    } finally {
      if (stateId === 2) {
        useVideoStore().isPublisherMuted = false;
        useVideoStore().isSubscriberMuted = false;
      }

      if (stateId !== 3) {
        useRouter().push(localePath(`/operator/requests/${requestId}/chat`));
      }

      isLoading.value = false;
    }
  };

  return {
    isLoading,
    updateRequestState,
  };
};
